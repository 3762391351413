<template>
  <div>
    <Loading v-show="show" />
    <div class="breadcrumb-banner-area">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="breadcrumb-text breadcrumb-text1">
              <h1 style="text-align: center">{{ Detials }}</h1>
              <!-- <div class="breadcrumb-bar">
                  <ul class="breadcrumb">
                    <li>
                      <router-link to="/"> Fac </router-link>
                    </li>
                    <li > Department </li>
                  </ul>
                </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Course Details Area Start-->
    <div class="course-details-area" style="padding: 40px 0 0">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12">
            <div class="course-details-content">
              <div class="single-course-details">
                <div class="row">
                  <div class="col-md-3">
                    <div class="overlay-effect">
                      <a><img loading="lazy" alt="" id="postImage_" /></a>
                    </div>
                  </div>
                  <div class="col-md-9">
                    <div class="single-item-text">
                      <h4 id="postTitle_"></h4>
                      <div class="course-text-content">
                        <p id="specialization_"></p>
                        <p id="degree_"></p>
                      </div>
                      <!-- <div class="single-item-content">
                        <div class="social-links">
                          <a href="#"><i class="fa fa-facebook"></i></a>
                          <a href="#"><i class="fa fa-twitter"></i></a>
                          <a href="#"><i class="fa fa-google"></i></a>
                          <a href="#"><i class="fa fa-instagram"></i></a>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--End of Course Details Area-->

    <!--Event Details Area Start-->
    <div class="event-details-area" style="padding: 0 0 60px">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="event-details-content">
              <div class="single-event-item">
                <div class="single-event-image single-event-image1">
                  <a aria-label="title" href="#">
                    <span>{{ CV }}</span>
                  </a>
                </div>
                <p id="postSubTitle_"></p>
                <div class="single-event-text" id="postDetial_"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--End of Event Details Area-->
  </div>
</template>
<script>
import Loading from "./Loading";
import axios from "axios";
import ArJson from "../../public/i18n/Ar.json";
import EnJson from "../../public/i18n/En.json";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      show: true,
      showAbout: true,
      showdownload: true,
      About: [],
      Leader: [],

      //translate
      ArTranslat: ArJson,
      EnTranslat: EnJson,
      translate: "",
      CV: "",
      Detials: "",
      ID: "",
      BrnID: "",
    };
  },
  methods: {
    getMemberById() {
      var self = this;
      self.ID = this.$route.params.id;
      self.BrnID = this.$route.params.BrnId;
      var bodyFormData1 = new FormData();
      bodyFormData1.append("check", "getPostById");
      bodyFormData1.append("Lang", localStorage.getItem("lang"));
      bodyFormData1.append("PostId", self.ID);
      bodyFormData1.append("FbrnId", self.BrnID);
      bodyFormData1.append("Type", "Univ.Faculty.Members");
      bodyFormData1.append("ParentId", "NTROOT0");
      bodyFormData1.append("PostName", "");
      bodyFormData1.append("Pno", "1");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormData1,
      })
        .then(function (response) {
          document.getElementById("postTitle_").innerText =
            response.data[0]["postTitle"];
          document.getElementById("postSubTitle_").innerText =
            response.data[0]["postSubTitle"];
          document
            .getElementById("postImage_")
            .setAttribute(
              "src",
              "https://api2.yuniv.net:444/images/post/" +
                response.data[0]["postImage"]
            );
          document.getElementById("specialization_").innerText =
            response.data[0]["specialization"];
          document.getElementById("degree_").innerText =
            response.data[0]["degree"];
          document.getElementById("postDetial_").innerHTML =
            response.data[0]["postDetails"];
        })
        .catch(function () {
          // console.log("error", response);
        });
      setTimeout(() => {
        self.show = false;
        document.querySelectorAll("div.loader").forEach(function (element) {
          element.setAttribute("hidden", true);
        });
      }, 100);
    },
  },
  mounted() {
    window.scroll(0, 0);
    var self = this;

    if (localStorage.getItem("lang") == "Ar") {
      self.translate = self.ArTranslat;
      document.getElementById("langfilewebsites")?.remove();
    } else {
      self.translate = self.EnTranslat;
      let link1 = document.createElement("link");
      link1.setAttribute("rel", "stylesheet");
      link1.id = "langfilewebsites";
      link1.setAttribute("href", "../../style-ltr.css");
      document.head.appendChild(link1);
    }
    self.Detials = self.translate[0]["Home"]["Detials"];
    self.CV = self.translate[0]["Home"]["CV"];
    self.getMemberById();
  },
};
</script>
<style scoped>
.single-course-details .single-item-text h4 {
  text-align: inherit;
}
.social-links {
  position: relative;
}
.social-links > a {
  display: inline-block;
}
</style>
